import { styled } from '@mui/material/styles';
import { Avatar, Box, DialogTitle, IconButton, List, Typography } from '@mui/material';
export var StyledIconButton = styled(IconButton)(function () { return ({
    width: '14px',
    height: '14px',
    top: '17px',
    left: '269px'
}); });
export var StyledEscalationDialogTitle = styled(DialogTitle)(function () { return ({
    width: '300px',
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#000000',
    padding: '24px'
}); });
export var StyledEscalationDialogDescription = styled(Typography)(function () { return ({
    width: '300px',
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    color: '#000000',
    letterSpacing: '0.25px',
    margin: '0px',
    paddingLeft: '24px',
    paddingRight: '24px'
}); });
export var StyledEscalationSubHeader = styled(StyledEscalationDialogDescription)(function () { return ({
    paddingTop: '0.6rem',
    fontWeight: 600,
}); });
export var StyledList = styled(List)(function () { return ({
    pt: 0,
    textTransform: 'capitalize',
    paddingTop: '4px',
    paddingBottom: '4px'
}); });
export var StyledEscalationAvatar = styled(Avatar)(function () { return ({
    background: '#F8F8F8',
    border: '1px solid #CECFCE'
}); });
export var StyledEscalationReasonArea = styled(Box)(function () { return ({
    margin: '0px',
    paddingTop: '0px',
    paddingBottom: '24px',
    paddingLeft: '24px',
    paddingRight: '24px'
}); });
export var StyledEscalationTagArea = styled(Box)(function () { return ({
    margin: '0px',
    paddingTop: '0px',
    paddingBottom: '4px',
    paddingLeft: '18px',
    paddingRight: '18px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
}); });
