import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLookupUserQuery } from '../../../../../api/auth0User';
import { Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CENTRAL_MANAGER } from '../../../../../common/pricer-services';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import StoreIcon from '@mui/icons-material/Store';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import RouterIcon from '@mui/icons-material/Router';
import CastleIcon from '@mui/icons-material/Castle';
import { useErrors } from '../../../../../common/hooks/errors';
var getServiceLink = function (service, loginHint, tenantId, servicesShowList) {
    var sxClickable = {
        cursor: 'pointer',
        ':hover': {
            color: '#387A26'
        }
    };
    var sxInactive = {
        cursor: 'pointer',
        color: '#ccc',
        ':hover': {
            color: '#ccc'
        }
    };
    if (service.type === CENTRAL_MANAGER) {
        var sx = !servicesShowList.includes(service.type)
            ? sxInactive
            : sxClickable;
        var lnk = !servicesShowList.includes(service.type)
            ? ''
            : "https://central-manager.".concat(service.configuration.pcsId, ".pcm.pricer-plaza.com").concat(loginHint);
        return {
            link: lnk,
            label: 'Central Manager',
            icon: _jsx(LocalGroceryStoreIcon, {}),
            sx: sx
        };
    }
    else if (service.type === 'app manager') {
        var sx = !servicesShowList.includes(service.type)
            ? sxInactive
            : sxClickable;
        var lnk = !servicesShowList.includes(service.type)
            ? ''
            : "https://am.se-apps.pricer-plaza.com?pcsId=".concat(service.configuration.pcsId).concat(loginHint);
        return {
            link: lnk,
            label: 'Basestation setup',
            icon: _jsx(RouterIcon, {}),
            sx: sx
        };
    }
    else if (service.type === 'store') {
        var sx = !servicesShowList.includes(service.type)
            ? sxInactive
            : sxClickable;
        var lnk = !servicesShowList.includes(service.type)
            ? ''
            : "".concat(service.configuration.url, "?tenantId=").concat(tenantId);
        return {
            link: lnk,
            label: 'Stores',
            icon: _jsx(StoreIcon, {}),
            sx: sx
        };
    }
    else if (service.type === 'iam') {
        var sx = !servicesShowList.includes(service.type)
            ? sxInactive
            : sxClickable;
        var lnk = !servicesShowList.includes(service.type)
            ? ''
            : "".concat(service.configuration.url, "?tenantId=").concat(tenantId);
        return {
            link: lnk,
            label: 'IAM',
            icon: _jsx(PersonAddAltIcon, {}),
            sx: sx
        };
    }
    else if (service.type === 'plaza home') {
        var sx = !servicesShowList.includes(service.type)
            ? sxInactive
            : sxClickable;
        var lnk = !servicesShowList.includes(service.type)
            ? ''
            : "".concat(service.configuration.url, "?tenantId=").concat(tenantId);
        return {
            link: lnk,
            label: 'Plaza Home',
            icon: _jsx(CastleIcon, {}),
            sx: sx
        };
    }
};
export default function ServiceLink(_a) {
    var service = _a.service, tenantId = _a.tenantId, servicesShowList = _a.servicesShowList;
    var user = useLookupUserQuery().data;
    var addAlert = useErrors().addAlert;
    var loginHint = user ? "?login_hint=".concat(user.email) : '';
    var handleClick = function (url) {
        if (url !== '') {
            window.open(url);
            return false;
        }
        else {
            addAlert('Service is not available for your current role');
            return false;
        }
    };
    var config = getServiceLink(service, loginHint, tenantId, servicesShowList);
    if (!config) {
        return null;
    }
    return (_jsx(Grid, { item: true, xs: 3, sx: config.sx, onClick: function () { return handleClick(config.link); }, children: _jsxs(Stack, { alignItems: "center", children: [config.icon, _jsx(Typography, { fontSize: 8, textAlign: "center", children: config.label })] }) }));
}
