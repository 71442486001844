import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
export var StyledTenantDetailsMetaWrapper = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    });
});
export var StyledTenantDetailMetaHeaderText = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        fontSize: '14px',
        textAlign: 'left',
        display: 'inline-block',
        opacity: '1',
        color: '#000000',
        font: theme.typography.fontFamily,
        fontWeight: '600',
        lineHeight: '20px',
        letterSpacing: '0.1px',
        marginTop: '8px',
        paddingBottom: '7px'
    });
});
export var StyledTenantDescription = styled(Typography)(function () { return ({
    color: '#000000',
    marginLeft: '15px',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    width: '40%'
}); });
export var StyledTenantText = styled(Typography)(function () { return ({
    color: '#000000',
    marginLeft: '15px',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    width: '80%'
}); });
export var StyledTenantDescriptionAttribute = styled(Typography)(function () { return ({
    color: '#000000',
    marginLeft: '15px',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    width: '40%'
}); });
export var StyledTenantServiceDescription = styled(Typography)(function () { return ({
    '&:hover': {
        cursor: 'pointer'
    },
    color: '#000000',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px'
}); });
export var StyledTenantDetailsInformation = styled('div')(function () { return ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '15px',
    marginBottom: '10px'
}); });
export var StyledTenantDetailsServiceInformation = styled('div')(function () { return ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
}); });
export var StyledTenantViewCardImageSideArea = styled('div')(function () { return ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'column'
}); });
export var StyledTenantViewCardImageDetails = styled('div')(function () { return ({
    position: 'relative',
    display: 'flex',
    height: '200px',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'column'
}); });
export var StyledTenantDetailsDivider = styled('div')(function () { return ({
    marginTop: '30px',
    borderBottom: '1px solid lightgray',
    width: '100%'
}); });
export var StyledTenantDetailsDividerInnerSection = styled('div')(function () { return ({
    marginTop: '7px',
    borderBottom: '1px solid lightgray',
    width: '100%'
}); });
export var StyledTenantDetailsServiceWrapper = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    });
});
