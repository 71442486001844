import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { MenuItem } from '@mui/material';
export var MenuItemDiv = styled('div')(function () { return ({
    display: 'flex',
    width: '100%',
    gap: '7px',
    color: '#054816'
}); });
export var MenuItemTypography = styled(Typography)(function () { return ({
    color: '#054816',
    fontWeight: 'bold'
}); });
export var StyledMenuItem = styled(MenuItem)(function () { return ({
    width: '300px'
}); });
export var StyledSelectTenantButton = styled('div')(function () { return ({
    textAlign: 'left',
    opacity: '1',
    fontWeight: 'bold',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    font: 'normal normal medium 20px/32px Raleway',
    height: '55px',
    width: 'auto',
    gap: '5px',
    '&:hover': {
        cursor: 'pointer'
    }
}); });
export var StyledTenantSelected = styled(Typography)(function () { return ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#054816',
    textDecoration: 'none',
    textAlign: 'center',
    opacity: '1',
    fontWeight: 'bold',
    display: 'inline-block',
    font: 'normal normal medium 20px/32px Raleway'
}); });
