import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TenantFilterBarItem } from './TenantFilterBarItem';
import React from 'react';
export var TenantFilterBarItems = function (props) {
    var _a = React.useState('All'), itemToBeActive = _a[0], setItemToBeActive = _a[1];
    var changeActiveItem = function (itemToBeActive) {
        setItemToBeActive(itemToBeActive);
        props.setFilterType(itemToBeActive);
    };
    return (_jsxs("div", { style: { display: 'flex' }, "data-testid": "filters", children: [_jsx(TenantFilterBarItem, { changeActiveItem: changeActiveItem, activeItem: itemToBeActive, name: 'All' }), _jsx(TenantFilterBarItem, { changeActiveItem: changeActiveItem, activeItem: itemToBeActive, name: 'Demo' }), _jsx(TenantFilterBarItem, { changeActiveItem: changeActiveItem, activeItem: itemToBeActive, name: 'Test' }), _jsx(TenantFilterBarItem, { changeActiveItem: changeActiveItem, activeItem: itemToBeActive, name: 'Staging' }), _jsx(TenantFilterBarItem, { changeActiveItem: changeActiveItem, activeItem: itemToBeActive, name: 'Production' }), _jsx(TenantFilterBarItem, { changeActiveItem: changeActiveItem, activeItem: itemToBeActive, name: 'Acceptance' }), _jsx(TenantFilterBarItem, { changeActiveItem: changeActiveItem, activeItem: itemToBeActive, name: 'Onprem' })] }));
};
