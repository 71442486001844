import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetTenantQuery } from '../../api/tenantApi';
import { TenantSelectorPageWrapper } from '../../components/atoms/TenantSelectorPageWrapper';
import { TenantDetailsSideArea } from '../tenantSelector/components/TenantSideArea/TenantDetailsSideArea';
import { LinkTenantContent } from './components/LinkTenantContent';
import { useErrors } from '../../common/hooks/errors';
export function LinkingPage() {
    var id = useParams().id;
    var _a = useGetTenantQuery(id), tenant = _a.data, isLoading = _a.isLoading, isError = _a.isError;
    var addAlert = useErrors().addAlert;
    useEffect(function () {
        if (isError) {
            addAlert('Failed to load tenant');
        }
    }, [addAlert, isError]);
    return (_jsx(TenantSelectorPageWrapper, { isLoading: isLoading, detail: _jsx(TenantDetailsSideArea, { tenant: tenant }), children: _jsx(Box, { sx: {
                padding: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }, children: _jsx(LinkTenantContent, { tenant: tenant }) }) }));
}
