import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Typography from '@mui/material/Typography';
import NoPhotographyOutlinedIcon from '@mui/icons-material/NoPhotographyOutlined';
export default function NoImage(_a) {
    var height = _a.height, width = _a.width, showText = _a.showText;
    var small = height === '50px';
    var fontSize = small ? '30px' : '100px';
    var imgMarginTop = small ? '5px' : '40px';
    return (_jsxs("div", { style: {
            height: height,
            width: width,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }, children: [_jsx(NoPhotographyOutlinedIcon, { sx: {
                    color: '#387A26',
                    fontSize: fontSize,
                    marginTop: imgMarginTop
                } }), showText && (_jsx(Typography, { color: '#387A26', variant: 'h2', sx: { marginTop: '50', marginBottom: '20px' }, children: "No image" }))] }));
}
