var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../common/config';
export function getCrmSystem(tenant, name) {
    if (!tenant.crmSystems) {
        return undefined;
    }
    return tenant.crmSystems.find(function (c) { return c.name === name; });
}
var responseHandler = function (response) {
    if (response.status === 401 || response.status === 403) {
        return Promise.resolve({ error: response.statusText });
    }
    return Promise.resolve(response.json());
};
export var tenantApi = createApi({
    reducerPath: 'tenants',
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().apiUrlTenants,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set('authorization', "Bearer ".concat(token));
            }
            else {
                console.error("No token set when calling Tenant API");
            }
            headers.set('Content-Type', 'application/json');
            return headers;
        }
    }),
    tagTypes: ['Tenant', 'Services'],
    endpoints: function (builder) { return ({
        getTenant: builder.query({
            query: function (id) { return ({
                url: "/api/v1/tenants/".concat(id),
                responseHandler: responseHandler
            }); },
            providesTags: function (result, error, id) { return [{ type: 'Tenant', id: id }]; }
        }),
        saveTenant: builder.mutation({
            query: function (tenant) { return ({
                url: "/api/v1/tenants/".concat(tenant.id),
                method: 'PUT',
                body: __assign(__assign({}, tenant), { links: tenant.links.map(function (link) { return link.id; }), id: undefined, createdAt: undefined, createdBy: undefined, updatedAt: undefined }),
                responseHandler: responseHandler
            }); },
            invalidatesTags: function (result, error, _a) {
                var id = _a.id;
                return [{ type: 'Tenant', id: id }];
            }
        }),
        deleteTenant: builder.mutation({
            query: function (id) { return ({
                url: "/api/v1/tenants/".concat(id),
                method: 'DELETE',
                responseHandler: 'text'
            }); },
            invalidatesTags: function (result, error, id) { return [{ type: 'Tenant', id: id }]; }
        }),
        createTenant: builder.mutation({
            query: function (tenant) {
                var _a;
                return ({
                    url: "/api/v1/tenants",
                    method: 'POST',
                    body: __assign(__assign({}, tenant), { links: (_a = tenant.links) === null || _a === void 0 ? void 0 : _a.map(function (link) { return link.id; }) }),
                    responseHandler: responseHandler
                });
            },
            invalidatesTags: function (result) { return [{ type: 'Tenant', id: result.id }]; }
        }),
        getServices: builder.query({
            query: function () { return ({
                url: '/api/v1/services',
                responseHandler: responseHandler
            }); },
            providesTags: function (result, error, id) { return ['Services']; }
        }),
        createService: builder.mutation({
            query: function (services) { return ({
                url: "/api/v1/services",
                method: 'POST',
                body: __assign({}, services),
                responseHandler: responseHandler
            }); },
            invalidatesTags: function (result) { return ['Services']; }
        }),
        deleteService: builder.mutation({
            query: function (id) { return ({
                url: "/api/v1/services/".concat(id),
                method: 'DELETE',
                responseHandler: 'text'
            }); },
            invalidatesTags: function (result, error, id) { return ['Services']; }
        }),
        editImage: builder.mutation({
            query: function (image) { return ({
                url: "/api/v1/tenants/".concat(image.tenantId, "/image"),
                method: 'PUT',
                body: __assign({}, image),
                responseHandler: responseHandler
            }); },
            invalidatesTags: function (result, error, _a) {
                var tenantId = _a.tenantId;
                return [
                    { type: 'Tenant', tenantId: tenantId }
                ];
            }
        }),
        AddImage: builder.mutation({
            query: function (image) { return ({
                url: "/api/v1/tenants/".concat(image.tenantId, "/image"),
                method: 'POST',
                body: __assign({}, image),
                responseHandler: responseHandler
            }); },
            invalidatesTags: function (result, error, _a) {
                var tenantId = _a.tenantId;
                return [
                    { type: 'Tenant', tenantId: tenantId }
                ];
            }
        })
    }); }
});
export var useGetTenantQuery = tenantApi.useGetTenantQuery, useSaveTenantMutation = tenantApi.useSaveTenantMutation, useDeleteTenantMutation = tenantApi.useDeleteTenantMutation, useCreateTenantMutation = tenantApi.useCreateTenantMutation, useGetServicesQuery = tenantApi.useGetServicesQuery, useCreateServiceMutation = tenantApi.useCreateServiceMutation, useDeleteServiceMutation = tenantApi.useDeleteServiceMutation, useEditImageMutation = tenantApi.useEditImageMutation, useAddImageMutation = tenantApi.useAddImageMutation;
