import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Alert, Collapse } from '@mui/material';
import { AlertPopupButton, AlertPopupButtonTypography } from './AlertPopup.styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
export default function AlertPopup(_a) {
    var onClose = _a.onClose, severity = _a.severity, message = _a.message;
    return (_jsx(Collapse, { in: true, children: _jsx(Alert, { variant: "filled", icon: _jsx(ErrorOutlineIcon, { fontSize: "large" }), severity: severity || 'error', sx: {
                backgroundColor: 'white',
                color: '#B3261E',
                mb: 1,
                border: '1px solid #CECFCE',
                display: 'flex',
                justifyContent: 'flex-start',
                alignContent: 'stretch',
                flexDirection: 'row',
                alignItems: 'center',
                height: '64px',
                marginBottom: '0px'
            }, action: _jsx(AlertPopupButton, { color: "inherit", onClick: onClose, children: _jsx(AlertPopupButtonTypography, { children: "Dismiss" }) }), children: _jsx("div", { style: { color: 'black' }, children: message }) }) }));
}
