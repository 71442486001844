var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetTenantsQuery } from '../../../api/profileApi';
import { useSaveTenantMutation } from '../../../api/tenantApi';
import TenantListLoading from '../../../components/atoms/TenantListLoading';
import AddLinkList from './AddLinkList';
import LinkedTenantList from './LinkedTenantList';
import { useAuthentication } from '../../../common/hooks/authentication';
export function LinkTenantContent(_a) {
    var _this = this;
    var currentTenant = _a.tenant;
    var navigate = useNavigate();
    var tenant = useAuthentication().tenant;
    var saveTenant = useSaveTenantMutation()[0];
    var allTenants = useGetTenantsQuery().data;
    var _b = useState((currentTenant === null || currentTenant === void 0 ? void 0 : currentTenant.links.map(function (link) { return link.id; })) || []), linkedTenants = _b[0], setLinkedTenants = _b[1];
    var _c = useState([]), linkableTenants = _c[0], setLinkableTenants = _c[1];
    useEffect(function () {
        if (allTenants) {
            setLinkableTenants(allTenants.filter(function (t) {
                return !(t.id === tenant ||
                    t.allChildren.find(function (childTenant) { return childTenant.id === tenant; }));
            }));
        }
    }, [setLinkableTenants, allTenants, tenant]);
    var currentLinks = (allTenants === null || allTenants === void 0 ? void 0 : allTenants.filter(function (ten) { return linkedTenants.includes(ten.id); }).map(function (t) { return ({
        image: t.image,
        title: t.name,
        id: t.id
    }); })) || [];
    var onSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
        var newLinks, newTenant, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    newLinks = (allTenants === null || allTenants === void 0 ? void 0 : allTenants.filter(function (ten) { return linkedTenants.includes(ten.id); }).map(function (ten) { return ten; })) || [];
                    newTenant = __assign(__assign({}, currentTenant), { links: newLinks });
                    return [4 /*yield*/, saveTenant(newTenant).unwrap()];
                case 1:
                    _a.sent();
                    handleClose();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error('Failed to save: ', e_1);
                    handleClose();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    if (!allTenants) {
        return _jsx(TenantListLoading, {});
    }
    var onRemove = function (id) {
        setLinkedTenants(linkedTenants.filter(function (asd) { return asd !== id; }));
    };
    var onAdd = function (id) {
        var newList = linkedTenants.slice();
        newList.push(id);
        setLinkedTenants(newList);
    };
    var handleClose = function () {
        navigate("/tenant/".concat(currentTenant.id));
    };
    return (_jsx(Paper, { sx: { margin: '10px', maxWidth: '400px', maxHeight: '100%' }, children: _jsxs(Stack, { spacing: 2, sx: { padding: '15px' }, children: [_jsx(Typography, { color: 'green', sx: { display: 'flex', justifyContent: 'center' }, children: "Connect tenants" }), _jsx(AddLinkList, { onAdd: onAdd, tenants: currentLinks, allTenants: linkableTenants }), _jsx(LinkedTenantList, { onRemove: onRemove, tenants: currentLinks }), _jsxs(Box, { display: "flex", justifyContent: "space-between", padding: 2, children: [_jsx(Button, { onClick: handleClose, children: "Cancel" }), _jsx(Button, { onClick: onSubmit, children: "Save" })] })] }) }));
}
