import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useGetServicesQuery } from '../../../../api/tenantApi';
import { removeInactiveServices } from '../../../../common/pricer-services';
import { SingleServiceView } from '../atoms/SingleServiceView';
import { useErrors } from '../../../../common/hooks/errors';
export function EditServicesView(_a) {
    var services = _a.services, onChange = _a.onChange;
    var _b = useGetServicesQuery(), serviceTypes = _b.data, isError = _b.isError;
    var addAlert = useErrors().addAlert;
    useEffect(function () {
        if (isError) {
            addAlert('Failed to load services');
        }
    }, [addAlert, isError]);
    var handleChange = function (services) {
        onChange(removeInactiveServices(services));
    };
    return (_jsx(_Fragment, { children: serviceTypes && (_jsx(SingleServiceView, { services: services, serviceConfig: serviceTypes, onChange: handleChange })) }));
}
