import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { StyledTenantViewCard, StyledTenantNameText } from './TenantViewCard.styles';
import React from 'react';
import TenantViewCardImage from './TenantViewCardImage';
export default function TenantViewCard(props) {
    var name = props.tenant.name;
    return (_jsxs(StyledTenantViewCard, { onClick: function () {
            props.handleOnClick(props.tenant.id);
        }, children: [_jsx(TenantViewCardImage, { tenantImage: props.tenant.image }), _jsx("div", { style: { borderBottom: '1px solid lightgray', width: '100%' } }), _jsx(StyledTenantNameText, { "data-testid": "tenantName", children: name })] }));
}
