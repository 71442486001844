import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider } from '@mui/material';
import { pricerTheme } from './theme';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import Application from './Application';
import { getConfig } from './common/config';
export var AppRoot = function () {
    return (_jsx(Auth0Provider, { domain: getConfig().auth0Domain, clientId: getConfig().auth0ClientId, authorizeTimeoutInSeconds: 5, httpTimeoutInSeconds: 5, authorizationParams: {
            audience: getConfig().auth0Audience,
            redirect_uri: window.location.origin
        }, children: _jsxs(ThemeProvider, { theme: responsiveFontSizes(createTheme(pricerTheme)), children: [_jsx(CssBaseline, {}), _jsx(Provider, { store: store, children: _jsx(BrowserRouter, { children: _jsx(Application, {}) }) })] }) }));
};
