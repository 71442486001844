import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
export var LoadingWrapper = styled('div')(function () { return ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
}); });
export var LoadingText = styled(Typography)(function () { return ({
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
}); });
