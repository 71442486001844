var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Box, Button, Paper, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { tenantApi, useAddImageMutation, useEditImageMutation } from '../../../api/tenantApi';
import CustomCollapse from '../../../components/atoms/CustomCollapse';
import { useAppDispatch } from '../../../redux/store';
import Typography from '@mui/material/Typography';
import { UploadImageButton } from '../../../components/atoms/UploadImageButton';
export var EditImageView = function (_a) {
    var tenant = _a.tenant;
    var navigate = useNavigate();
    var doEditImage = useEditImageMutation()[0];
    var doAddImage = useAddImageMutation()[0];
    var dispatch = useAppDispatch();
    var routerUrl = window.location.href;
    var image = useState({
        id: tenant.image,
        tenantId: tenant.id,
        imageContent: ''
    })[0];
    var _b = useState(''), errorMessage = _b[0], setErrorMessage = _b[1];
    var handleClose = function () {
        navigate("/tenants/".concat(tenant.id));
    };
    var onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!image.imageContent || image.imageContent === '') {
                        setErrorMessage('You must provide an image');
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    if (!routerUrl.includes('add-image')) return [3 /*break*/, 3];
                    return [4 /*yield*/, doAddImage(image).unwrap()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, doEditImage(image).unwrap()];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5:
                    dispatch(tenantApi.util.invalidateTags(['Tenant']));
                    handleClose();
                    return [3 /*break*/, 7];
                case 6:
                    e_1 = _a.sent();
                    if (e_1.status === 403) {
                        setErrorMessage('You do not have permission to edit. You need at least Editor role.');
                        return [2 /*return*/];
                    }
                    else if (e_1.data && e_1.data.error) {
                        setErrorMessage('An error occurred while updating the image. ' + e_1.data.error);
                        return [2 /*return*/];
                    }
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var onChange = function (f) {
        image.imageContent = f.image;
    };
    return (_jsx(Paper, { sx: { margin: '10px', maxWidth: '500px', maxHeight: '100%' }, children: _jsx(Box, { paddingLeft: 3, paddingTop: 1, paddingBottom: 2, children: _jsxs(Stack, { maxWidth: "400px", spacing: 2, children: [_jsx(CustomCollapse, { errorMessage: errorMessage, onClose: function () { return setErrorMessage(''); } }), _jsx(Typography, { variant: "h4", children: "Add/Change Image" }), _jsx(UploadImageButton, { onSetImage: function (name, data) { return onChange(__assign(__assign({}, image), { image: data })); }, onRemoveImage: function () { return onChange(__assign(__assign({}, image), { image: '' })); } }), _jsxs(Box, { display: "flex", justifyContent: "space-between", padding: 2, paddingTop: 15, children: [_jsx(Button, { onClick: handleClose, children: "Cancel" }), _jsx(Button, { onClick: onSubmit, children: "Update" })] })] }) }) }));
};
