import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import { getConfig } from '../common/config';
import { sha256 } from 'js-sha256';
var initialState = {
    token: undefined,
    tags: undefined,
    role: '',
    tenant: '',
    parent: '',
    isPricer: false
};
export var authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        addToken: function (state, _a) {
            var payload = _a.payload;
            var decoded = jwtDecode(payload);
            return {
                token: payload,
                role: decoded['https://pricer.com/role'],
                tags: decoded['https://pricer.com/tags'],
                tenant: decoded['https://pricer.com/tenant'],
                parent: decoded['https://pricer.com/parent'],
                isPricer: verifyIsPricer(decoded)
            };
        },
        logout: function (state) { return initialState; }
    }
});
var verifyIsPricer = function (dToken) {
    var parent = dToken['https://pricer.com/parent'];
    return (sha256(parent) === getConfig().pParentHash ||
        sha256(parent) === getConfig().penTestParentHash);
};
