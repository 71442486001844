import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import MetaData from './atoms/MetaData';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import AbcIcon from '@mui/icons-material/Abc';
import { StyledTenantDetailMetaHeaderText, StyledTenantDetailsMetaWrapper } from '../TenantDetailsSideArea.styles';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import PlaceIcon from '@mui/icons-material/Place';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Text } from './atoms/Text';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import { hasValue, PCS_REGIONS } from '../../../../../common/pricer-services';
var getStatusIcon = function (active, requested) {
    if (active) {
        return _jsx(CheckIcon, { color: "success" });
    }
    if (requested) {
        return _jsx(HourglassBottomIcon, {});
    }
    return _jsx(QuestionMarkIcon, {});
};
var getStatus = function (active, requested) {
    if (active) {
        return 'Active';
    }
    if (requested) {
        return 'Requested';
    }
    return 'Unknown';
};
export default function PcsSystem(_a) {
    var pcs = _a.pcs, tenant = _a.tenant;
    var active = hasValue(pcs.pcsId);
    var requested = !active && hasValue(pcs.requestedPcsId);
    var status = getStatus(active, requested);
    var statusIcon = getStatusIcon(active, requested);
    var name = pcs.pcsId ? pcs.pcsId : pcs.requestedPcsId;
    var stuckEula = tenant.contractStatus === 'REQUESTED';
    var actualStatus = stuckEula ? 'Pending' : status;
    var profile = pcs.profile || 'Unknown';
    var region = PCS_REGIONS[pcs.region] || '-';
    return (_jsxs("div", { style: { padding: '15px' }, children: [_jsx(StyledTenantDetailsMetaWrapper, { children: _jsx(StyledTenantDetailMetaHeaderText, { "data-testid": "cm-title", children: "Central manager" }) }), _jsx(MetaData, { icon: _jsx(AbcIcon, {}), attribute: "Name:", value: name, dataTestId: "cm-name" }), _jsx(MetaData, { icon: statusIcon, attribute: "Status:", value: actualStatus, dataTestId: "cm-status" }), _jsx(MetaData, { icon: _jsx(PlaceIcon, {}), attribute: "Region:", value: region, dataTestId: "cm-region" }), active && (_jsx(MetaData, { icon: _jsx(FormatSizeIcon, {}), attribute: "Profile:", value: profile, dataTestId: "cm-profile" })), stuckEula && (_jsx(Text, { icon: _jsx(WarningIcon, { color: "warning" }), value: 'Tenant is pending customer signing the EULA' }))] }));
}
