import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { StyledTenantDescription, StyledTenantDetailsServiceInformation, StyledTenantDetailsServiceWrapper, StyledTenantServiceDescription } from '../TenantDetailsSideArea.styles';
import LinkIcon from '@mui/icons-material/Link';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
export default function CrmItem(_a) {
    var crmName = _a.crmName, url = _a.url;
    var handleClick = function (url) {
        window.open(url);
    };
    return (_jsxs(StyledTenantDetailsServiceWrapper, { children: [_jsxs(StyledTenantDetailsServiceInformation, { children: [_jsx(LinkIcon, {}), _jsx(StyledTenantDescription, { children: crmName })] }), _jsx(StyledTenantServiceDescription, { onClick: function () { return handleClick(url); }, children: _jsx(ArrowForwardIcon, {}) })] }));
}
