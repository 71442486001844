import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
export var TenantSearchBarSingleRow = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: '3px'
    });
});
export var StyledTenantFilterBar = styled('div')(function () { return ({
    display: 'flex',
    width: '100%',
    height: '68px',
    alignItems: 'center',
    padding: '8px',
    paddingRight: '42px'
}); });
export var StyledTenantFilterBarItem = styled(Typography)(function () { return ({
    color: '#000000',
    fontWeight: '600',
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    '&:hover': {
        cursor: 'pointer'
    }
}); });
export var StyledTenantFilterBarItemWrapper = styled('div')(function (props) { return ({
    borderBottom: props.active ? '3px solid #387A26' : 'none',
    marginLeft: '30px',
    padding: '5px'
}); });
