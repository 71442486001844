import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { EditTenantView } from './components/EditTenantView';
import { useGetTenantQuery } from '../../api/tenantApi';
import { TenantSelectorPageWrapper } from '../../components/atoms/TenantSelectorPageWrapper';
import { useParams } from 'react-router-dom';
import { TenantDetailsSideArea } from '../tenantSelector/components/TenantSideArea/TenantDetailsSideArea';
import { useErrors } from '../../common/hooks/errors';
export function TenantEditorPage() {
    var id = useParams().id;
    var _a = useGetTenantQuery(id), tenant = _a.data, isLoading = _a.isLoading, isError = _a.isError;
    var addAlert = useErrors().addAlert;
    useEffect(function () {
        if (isError) {
            addAlert('Failed to load tenant');
        }
    }, [addAlert, isError]);
    return (_jsx(TenantSelectorPageWrapper, { isLoading: isLoading, detail: _jsx(TenantDetailsSideArea, { tenant: tenant }), children: _jsx(EditTenantView, { tenant: tenant }) }));
}
