import { jsx as _jsx } from "react/jsx-runtime";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import StoreIcon from '@mui/icons-material/Store';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { BorderColorRounded, ManageAccountsRounded, VisibilityRounded } from '@mui/icons-material';
export default function EscalationIcon(_a) {
    var role = _a.role, selectedRole = _a.selectedRole;
    if (role === 'pricer-viewer') {
        if (selectedRole === 'pricer-viewer') {
            return _jsx(VisibilityRounded, { sx: { color: '#054816' } });
        }
        return _jsx(VisibilityOutlinedIcon, { sx: { color: '#054816' } });
    }
    else if (role === 'pricer-editor') {
        if (selectedRole === 'pricer-editor') {
            return _jsx(BorderColorRounded, { sx: { color: '#054816' } });
        }
        return _jsx(BorderColorOutlinedIcon, { sx: { color: '#054816' } });
    }
    else if (role === 'tenant-admin') {
        if (selectedRole === 'tenant-admin') {
            return _jsx(StoreIcon, { sx: { color: '#054816' } });
        }
        return _jsx(StoreOutlinedIcon, { sx: { color: '#054816' } });
    }
    else if (role === 'business-admin') {
        if (selectedRole === 'business-admin') {
            return _jsx(PersonPinIcon, { sx: { color: '#054816' } });
        }
        return _jsx(PersonPinOutlinedIcon, { sx: { color: '#054816' } });
    }
    else if (role === 'metrics') {
        if (selectedRole === 'metrics') {
            return _jsx(AssessmentIcon, { sx: { color: '#054816' } });
        }
        return _jsx(AssessmentOutlinedIcon, { sx: { color: '#054816' } });
    }
    if (selectedRole === 'pricer-admin') {
        return _jsx(ManageAccountsRounded, { sx: { color: '#054816' } });
    }
    return _jsx(ManageAccountsOutlinedIcon, { sx: { color: '#054816' } });
}
