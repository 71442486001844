import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ErrorLevel, errorSlice } from '../../redux/errorSlice';
import ErrorView from './ErrorView';
import AlertPopup from './AlertPopup';
import { Stack } from '@mui/material';
var ErrorHandler = function (_a) {
    var children = _a.children;
    var error = useAppSelector(function (state) { return state.error; });
    var dispatch = useAppDispatch();
    if (!error) {
        return _jsx(ErrorView, { message: 'Ooops.. Something went wrong...' });
    }
    switch (error.errorLevel) {
        case ErrorLevel.NONE:
            return children;
        case ErrorLevel.ALERT:
            return (_jsxs(Stack, { children: [_jsx(AlertPopup, { severity: 'error', message: error.message, onClose: function () { return dispatch(errorSlice.actions.clearError()); } }), children] }));
        case ErrorLevel.PAGE:
            return (_jsx(ErrorView, { message: 'Ooops.. Something went wrong: ' + error.message }));
        default:
            return children;
    }
};
export default ErrorHandler;
