var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuItemDiv, MenuItemTypography, StyledMenuItem, StyledSelectTenantButton, StyledTenantSelected } from './SettingsMenu.styles';
import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Divider, Menu } from '@mui/material';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../../common/hooks/authentication';
import { useLookupUserQuery } from '../../../api/auth0User';
import { useGetProfileQuery } from '../../../api/profileApi';
import TenantImage from '../../molecules/TenantImage';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
var capitalizeFirstLetter = function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
var getName = function (name, email) {
    if (name) {
        return name;
    }
    var emailName = email.split('@')[0];
    return emailName.replace('.', ' ');
};
export default function PlazaUsersSettingsMenu() {
    var _this = this;
    var _a, _b;
    var _c = useAuthentication(), parentTenantId = _c.parentTenant, role = _c.role;
    var profileTenants = useGetProfileQuery().data;
    var navigate = useNavigate();
    var _d = useLookupUserQuery(), lookup = _d.data, isLoading = _d.isLoading;
    var name = isLoading
        ? 'Loading...'
        : getName(lookup.fullName, lookup.email);
    var parentTenant = ((_a = profileTenants === null || profileTenants === void 0 ? void 0 : profileTenants.tenants) === null || _a === void 0 ? void 0 : _a.find(function (tenant) { return tenant.id === parentTenantId; })) ||
        undefined;
    var currentTenant = (profileTenants === null || profileTenants === void 0 ? void 0 : profileTenants.tenant) || undefined;
    var tenantName = (currentTenant === null || currentTenant === void 0 ? void 0 : currentTenant.name) || 'Unknown';
    var _e = React.useState(null), anchorElUser = _e[0], setAnchorElUser = _e[1];
    var toggleMenu = function (event) {
        if (!anchorElUser) {
            setAnchorElUser(event.currentTarget);
        }
    };
    var handleCloseUserMenu = function () {
        setAnchorElUser(null);
    };
    var gotoProfile = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            handleCloseUserMenu();
            navigate('/profile');
            return [2 /*return*/];
        });
    }); };
    var _f = useAuth0(), logout = _f.logout, loginWithRedirect = _f.loginWithRedirect;
    var currentRoleName = capitalizeFirstLetter(role.replace('pricer-', ''));
    var goBackToTenantSelector = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, loginWithRedirect()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    if (!parentTenant) {
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", { children: _jsxs(StyledSelectTenantButton, { onClick: toggleMenu, children: [_jsx(TenantImage, { image: parentTenant.image, showText: false, style: { objectFit: 'contain', borderStyle: 'none' } }), _jsxs(StyledTenantSelected, { children: [name, _jsx("br", {}), _jsxs("small", { "data-testid": "current-name-and-role", children: [tenantName, " ( ", currentRoleName, " )"] })] }), _jsx(ArrowDropDownIcon, { style: { color: '#054816' } }), _jsxs(Menu, { sx: { mt: '55px' }, anchorEl: anchorElUser, anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }, keepMounted: true, transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }, open: Boolean(anchorElUser), onClose: handleCloseUserMenu, children: [((_b = profileTenants === null || profileTenants === void 0 ? void 0 : profileTenants.tenants) === null || _b === void 0 ? void 0 : _b.length) > 1 && (_jsx(StyledMenuItem, { onClick: goBackToTenantSelector, children: _jsxs("div", { children: [_jsxs(MenuItemDiv, { children: [_jsx(SwitchAccountIcon, { fontSize: 'small' }), _jsx(MenuItemTypography, { children: "Switch tenant" })] }), _jsx("div", { children: _jsx(MenuItemTypography, { fontSize: '10px', "data-testid": "current-tenant", children: tenantName }) })] }) })), _jsx(StyledMenuItem, { onClick: gotoProfile, children: _jsxs(MenuItemDiv, { children: [_jsx(ManageAccountsIcon, { fontSize: 'small' }), _jsx(Typography, { fontWeight: 'bold', children: "Profile" })] }) }), _jsx(Divider, {}), _jsx(StyledMenuItem, { onClick: function () {
                                return logout({ logoutParams: { returnTo: window.location.origin } });
                            }, children: _jsxs(MenuItemDiv, { children: [_jsx(LogoutIcon, { fontSize: 'small' }), _jsx(Typography, { fontWeight: 'bold', children: "Logout" })] }) })] })] }) }));
}
