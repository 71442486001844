import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { EditableListItem2 } from '../../../../components/atoms/EditableListItem2';
import { FormSelect } from '../../../../components/atoms/FormSelect';
import { PCS_REGIONS } from '../../../../common/pricer-services';
export function FieldsView(_a) {
    var name = _a.name, configuration = _a.configuration, fields = _a.fields, onChange = _a.onChange;
    return (_jsx(_Fragment, { children: fields.map(function (field) {
            if (field === 'region') {
                return (_jsx(FormSelect, { id: "".concat(name, "-").concat(field), label: field, value: configuration[field] || '', onChange: function (value) { return onChange(name, field, value); }, options: PCS_REGIONS }, "".concat(name, "-").concat(field)));
            }
            else {
                return (_jsx(EditableListItem2, { id: "".concat(name, "-").concat(field), label: field, value: configuration[field] || '', onChange: function (value) { return onChange(name, field, value); } }, "".concat(name, "-").concat(field)));
            }
        }) }));
}
